<template>
  <div>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="page_title" label="页面标题"></el-table-column>
        <el-table-column prop="share_title" label="分享标题"></el-table-column>
        <el-table-column label="二维码">
          <template #default="s">
            <div v-if="s.row.official_qrcode">
              <a :href="s.row.official_qrcode | tomedia" target="_blank">
                <img :src="s.row.official_qrcode | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem"
                     alt="">
              </a>
            </div>
            <div v-else>
              <el-button @click="makeQrcode(s.row,'official')" size="small">生成</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="小程序码">
          <template #default="s">
            <div v-if="s.row.wxapp_qrcode">
              <a :href="s.row.wxapp_qrcode | tomedia" target="_blank">
                <img :src="s.row.wxapp_qrcode | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
              </a>
            </div>
            <div v-else>
              <el-button @click="makeQrcode(s.row,'wxapp')" size="small">生成</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template #header>
            <el-button @click="$router.push({
          name:'voice-red-packet-design-edit',
          params:$route.params
          })" size="mini" type="primary">添加聚合页
            </el-button>
          </template>
          <template #default="s">
            <div style="margin-bottom: .5rem">
              <el-button @click="copy(`/pages/plugin/voice-red-packet/custom?id=${s.row.id}`)" size="mini">复制链接
              </el-button>
              <el-button @click="copyH5(`visit-p=voice-red-packet-custom&id=${s.row.id}`)" size="mini">复制H5链接
              </el-button>
            </div>
            <el-button @click="$router.push({
            name:'voice-red-packet-design-edit',
            params:$route.params,
            query:{id:s.row.id}
            })" size="mini" type="primary">编辑
            </el-button>
            <el-popconfirm @confirm="del(s.row)" style="margin-left: 1rem" title="确定删除吗？">
              <el-button slot="reference" size="mini" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      page: 1,
      list: [],
      total: 0
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    makeQrcode(item, channel) {
      this.$api.plugin.voiceRedPacket.designQrcodeGet({id: item.id, channel: channel}).then(() => {
        this.$message.success("生成成功");
        this.loadList();
      })
    },
    copyH5(str) {
      this.$copyText(location.origin + "/app/" + this.$route.params.uni_acid + "/index?" + str).then(() => {
        this.$message.success("复制成功");
      })
    },
    copy(str) {
      this.$copyText(str).then(() => {
        this.$message.success("复制成功");
      })
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    del({id}) {
      this.$api.plugin.voiceRedPacket.designDel({id}).then(() => {
        this.$message.success("操作成功");
        this.loadList();
      })
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.voiceRedPacket.designSearch({
        page: this.page,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>